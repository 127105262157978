import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-light.png'

const Footer = () => {
    const location = useLocation();
    const [footerPadding, setfooterPadding] = useState(false);
    useEffect(() => {
      if(location.pathname === "/" || location.pathname === "/service-01"){
        setfooterPadding(true)
      }else{
        setfooterPadding(false)
      }
    }, [location])
    
    return (
        <>
            <div className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo} width="119" height="40" alt="Cretech" />
                                </Link>
                                <p className="footer-widget__text">Trabajamos con amor haciendo realidad las ideas de nuestros clientes. Si lo puedes imaginar, lo puedes programar. 🤖</p>
                                <ul className="footer-widget__social">
                                    <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Servicios</h3>
                                <ul className="footer-widget__links">
                                    <li>
                                        <Link to="/">Desarrollo de Software</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Marketing Digital</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Consultoría de Software</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Diseño UI/UX</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Software POS - ERP</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Desarrollo de hardware</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Contáctanos</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="tel:+573192432568">+ 57 319 243 2568</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="mailto:contacto@solucionesuraweb.com">contacto@solucionesuraweb.com</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        Santa fe de Antioquia, <br /> Colombia
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; Diseñado con 💖 <span className="dynamic-year"></span> por URAWEB.</p>
                </div>
            </div>
        </>
    )
}

export default Footer