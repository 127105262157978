import React from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact.jpg'
import Breadcumb from '../Breadcumb/Main'

const Contact = () => {
    return (
        <>
        <Breadcumb Title="Contáctanos" Breadcumb="Contacto" />
            <section className="contact-one section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <form action="assets/inc/sendemail.php" className="contact-one__form contact-form-validated">
                                <div className="section-title">
                                    <p className="section-title__text">Contáctanos</p>
                                    <h2 className="section-title__title">Déjanos tus datos para comunicarnos contigo</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12">
                                        <input type="text" placeholder="Nombre completo" name="name" />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input type="email" placeholder="Correo electrónico" name="email" />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <textarea name="message" placeholder="Motivo de contacto"></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="thm-btn contact-one__btn" type="submit"><span>Enviar mensaje</span></button>
                                    </div>
                                </div>
                            </form>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms"
                                style={{backgroundImage: `url(${BG})`}}>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Ubicación</h3>
                                    <p className="contact-one__item__text">Santa fe de Antioquia, <br />
                                        Colombia</p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Teléfono</h3>
                                    <p className="contact-one__item__text">
                                        Celular: <Link to="tel:+573192432568">+57 319 243 2568</Link></p>

                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Correo</h3>
                                    <p className="contact-one__item__text"><Link
                                        to="mailto:contacto@solucionesuraweb.com">contacto@solucionesuraweb.com</Link><br /><Link
                                            to="mailto:contacto@mailermartinez.com">contacto@mailermartinez.com</Link></p>

                                </div>
                                <div className="contact-one__item">
                                    <ul className="contact-one__social">
                                        <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="google-map google-map--contact">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
                    className="google-map__one" title='gmap' allowFullScreen></iframe>

            </section> */}
        </>
    )
}

export default Contact